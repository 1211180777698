import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import LoadingStatus from "../components/LoadingStatus";

const ErrorPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BAM Applied Insights</title>
      </Helmet>
      <div className="flex flex-col min-h-screen scroll-smooth font-roboto">
        <Header showNav={false} />
        <main className="flex flex-col flex-1 w-full overflow-hidden bg-bam-light-blue/50 pt-16">
          <LoadingStatus loadingType="page_error" />
        </main>
      </div>
    </>
  );
};

export default ErrorPage;
